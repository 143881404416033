import axios from 'axios';
class APIDriver {
    constructor(thisService = 'resources') {
        this.setService(thisService);
        this.setApi(
            {
                baseURL: process.env.REACT_APP_API_BASEURL,
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

    }
    setBearerProtection() {
        this.api.interceptors.request.use(
            config => {
                const bearer = localStorage.getItem('bearer-token');
                if (bearer) {
                    config.headers.Authorization = `Bearer ${bearer}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );
        this.api.interceptors.response.use(
            response => response,
            error => {
                return Promise.reject(error);
            }
        );
    }
    unsetBearerProtection() {
        this.api.interceptors.request.use(
            config => {
                return config;
            },
            error => Promise.reject(error)
        );
        this.api.interceptors.response.use(
            response => response,
            error => {
                return Promise.reject(error);
            }
        );
    }
    setApi(api) {
        this.api = axios.create(api);
    }
    setService(service) {
        this.service = service;
    }
    async get(url, id = null) {
        let response;
        let uriString = `${this.service}`;
        if (url && url.length > 0) {
            uriString += `/${url}`;
        }
        if (!Array.isArray(id)) {
            if (id === null) {
                uriString += '';
            } else {
                uriString += `/${id}`;
            }
            response = await this.api.get(`${uriString}`);
        } else {
            response = await this.api.get(`${uriString}/${id.join('/')}`);
        }
        return response.data;
    }
    async getAll(url, filters = null) {
        let uriString = `${this.service}`;
        if (url && url.length > 0) {
            uriString += `/${url}`;
        }
        if (filters) {
            uriString += '?';
            for (let key in filters) {
                uriString += `${key}=${filters[key]}&`;
            }
            uriString = uriString.slice(0, -1);
        }
        let response = await this.api.get(`${uriString}`);
        return response.data;
    }
    async post(url, data) {
        let uriString = `${this.service}`;
        if (url && url.length > 0) {
            uriString += `/${url}`;
        }
        let response = await this.api.post(`${uriString}`, data);
        return response.data;

    }
    async upload(url, data) {
        let uriString = `${this.service}`;
        if (url && url.length > 0) {
            uriString += `/${url}`;
        }
        let response = await this.api.post(`${uriString}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;

    }
    async put(url, id, data) {
        let uriString = `${this.service}`;
        if (url && url.length > 0) {
            uriString += `/${url}`;
        }
        return await this.api.put(`${uriString}/${id}`, data);
    }
    async patch(url, id, data) {
        //return await this.api.patch(`${this.service}/${url}/${id}`);
        let uriString = `${this.service}`;
        if (url && url.length > 0) {
            uriString += `/${url}`;
        }
        return await this.api.patch(`${uriString}/${id}`, data);
    }
    async delete(url, id) {
        let uriString = `${this.service}`;
        if (url && url.length > 0) {
            uriString += `/${url}`;
        }
        return await this.api.delete(`${uriString}/${id}`);
    }
}
export default APIDriver;