import APIDriver from './api/APIDriver'
const api = new APIDriver('companyusers')

const methods = {
    patch: (id, data) => {
        api.setBearerProtection();
        return api.patch('patch', id, data);
    },
    signIn: (data) => {
        api.unsetBearerProtection()
        return api.post('sign-in', data)
    },
    signOut: () => {
        localStorage.clear();
    },
    requestPasswordRecovery: (data) => {
        api.unsetBearerProtection()
        return api.post('request-password-recovery', data)
    },
    checkPasswordRecoveryToken: (data) => {
        api.unsetBearerProtection()
        return api.post('check-password-recovery-token', data)
    },
    setNewPassword: (id, data) => {
        api.unsetBearerProtection()
        return api.patch('set-new-password', id, data)
    },
    signUp: (data) => {
        api.unsetBearerProtection()
        return api.post('sign-up', data)
    },
    byCompany: (id) => {
        api.setBearerProtection();
        return api.get('company', id);
    },
    byBusinessRole: (id) => {
        api.setBearerProtection();
        return api.get('businessrole', id);
    },
    get: (id) => {
        api.setBearerProtection();
        return api.get('', id);
    },
    list: () => {
        api.setBearerProtection();
        return api.getAll('');
    },
    create: (data) => {
        api.setBearerProtection();
        return api.post('', data);
    },
    update: (id, data) => {
        api.setBearerProtection();
        return api.patch('', id, data);
    },
    remove: (id) => {
        api.setBearerProtection();
        return api.delete('', id);
    }
}


export default methods;