import React from 'react'
import Navbar from '../../components/Public/Navbar'
import Footer from '../../components/Public/Footer'
import Main from './GoodPractices/MainSearch'

export default function GoodPractices() {
  return (
    <>
      <Navbar />
      <Main />
      <Footer />
    </>
  )
}
