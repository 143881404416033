import React from 'react'
import Navbar from '../../components/Public/Navbar'
import Footer from '../../components/Public/Footer'
import Main from './GoodPractices/VideoGallery'

export default function GoodPracticesVideoGallery() {
    return (
        <>
            <Navbar />
            <Main />
            <Footer />
        </>
    )
}
