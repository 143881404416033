import React from 'react'
import FSLightBox from 'fslightbox-react'

export default function VideoGalleryItem(props) {

    const { photo, videoUrl, text, origin } = props
    const [showVideo, setShowVideo] = React.useState(false)

    return (
        <div className="col">
            <div className="">
                <FSLightBox
                    toggler={showVideo}
                    sources={[
                        <video
                            allow="fullscreen"
                            frameBorder="0"
                            width={1920}
                            height={1080}
                            controls
                            controlsList="nodownload"
                            className='video'
                            autoPlay
                        >
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                    ]}
                />
                <a onClick={() => setShowVideo(!showVideo)}>
                    <img src={photo} className="img-fluid card-img-top" />
                </a>
                <div className="m-2">
                    <p className="card-text text-primary">
                        {text}
                    </p>
                    <b>
                        <p className="card-text text-primary">
                            {origin}
                        </p>
                    </b>
                </div>
            </div>
        </div>
    )
}