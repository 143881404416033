import React from 'react'
import IMG_ICON_DESCARGA from '../../assets/img/icon-descarga.svg'

export default function GoodPractice(props) {

    const { title, description, url } = props

    const goToUrl = (url) => {
        window.open(url, '_blank')
    }

    return (
        <>
            <hr className="my-4" />
            <div className="d-flex align-items-center">
                <div className="me-4">
                    <h3 className="h5 fw-bold">
                        {title}
                    </h3>
                    <p className="mb-0">
                        {description}
                    </p>
                </div>
                <a onClick={
                    () => goToUrl(url)
                } className='react-link'>
                    <img src={IMG_ICON_DESCARGA} alt="Descargar" className="me-5" />
                </a>
            </div>
        </>
    )
}
