import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import sessionUtils from './utilities/SessionHandler';

function App() {

  const navigateTo = useNavigate();

  const handleDefaultRoute = () => {

    //if process.env.REACT_APP_BUILD_TARGET is in array

    if (['administration', 'company'].includes(process.env.REACT_APP_BUILD_TARGET)) {
      let token = sessionUtils.getCredentials();
      try {
        if (token) {
          navigateTo('/home');
        } else {
          throw new Error('No token found');
        }
      } catch (error) {
        if (process.env.REACT_APP_BUILD_TARGET === 'company') {
          navigateTo('/sign-on');
        } else {
          navigateTo('/sign-in');
        }
      }
    } else if (process.env.REACT_APP_BUILD_TARGET === 'public') {
      navigateTo('/home');
    }

  }

  

  useEffect(() => {
    handleDefaultRoute();
  },
    []);

  return;
}

export default App;
