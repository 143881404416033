import React, { Fragment } from 'react'
import IMG_ICON_BASES_CONCURSO from '../../../assets/img/icon02-bases-concurso.svg'
import IMG_ICON_BUENAS_PRACTICAS from '../../../assets/img/icon02-buenas-practicas.svg'
import IMG_ICON_PREGUNTAS_FRECUENTES from '../../../assets/img/icon02-preguntas-frecuentes.svg'
import IMG_ICON_CONTACTO from '../../../assets/img/icon02-contacto.svg'
import GoodPractice from '../../../components/Public/GoodPractice'
import { useNavigate } from 'react-router-dom'

export default function Home() {

    const navigateTo = useNavigate()

    const btnBasesOnClick = () => {
        navigateTo('/competition-rules')
    }

    const btnBuenasPracticasOnClick = () => {
        navigateTo('/good-practices')
    }

    const btnFaqOnClick = () => {
        navigateTo('/faq')
    }

    const btnContactoOnClick = () => {
        navigateTo('/contact-us')
    }

    const currentYear = new Date().getFullYear() % 100

    return (
        <Fragment>
        </Fragment>
    )

}