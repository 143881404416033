import React, { useEffect, useState } from 'react'
import IMG_ICON_BASES_CONCURSO from '../../../assets/img/icon02-bases-concurso.svg'
import IMG_ICON_BUENAS_PRACTICAS from '../../../assets/img/icon02-buenas-practicas.svg'
import IMG_ICON_PREGUNTAS_FRECUENTES from '../../../assets/img/icon02-preguntas-frecuentes.svg'
import IMG_ICON_CONTACTO from '../../../assets/img/icon02-contacto.svg'
import { useNavigate } from 'react-router-dom'
import notificationService from '../../../services/notifications'

export default function Main() {


    const navigateTo = useNavigate()

    const [messageStatus, setMessageStatus] = useState('')
    const [messageText, setMessageText] = useState('')
    const [messageClass, setMessageClass] = useState('alert alert-dismissible fade')
    const [recaptchaToken, setRecaptchaToken] = useState(null)

    const [formData, setFormData] = React.useState({
        fromName: '',
        fromEmail: '',
        fromCompany: '',
        notificationMessage: '',
        'g-recaptcha-response': ''
    })

    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(process.env.REACT_APP_SITE_RECAPTCHA_KEY, { action: "contactus" })
                .then(token => {
                    setRecaptchaToken(token)
                    setFormData({
                        ...formData,
                        'g-recaptcha-response': token
                    })
                })
        })
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=" + process.env.REACT_APP_SITE_RECAPTCHA_KEY
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    }, [])

    const btnBasesOnClick = () => {
        navigateTo('/competition-rules')
    }

    const btnBuenasPracticasOnClick = () => {
        navigateTo('/good-practices')
    }

    const btnFaqOnClick = () => {
        navigateTo('/faq')
    }

    const btnContactoOnClick = () => {
        navigateTo('/contact-us')
    }

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
        document.getElementById(event.target.name).classList.remove('is-invalid')
    }

    const btnSendOnClick = async () => {
        let newFormData = {
            ...formData,
            'g-recaptcha-response': recaptchaToken
        }
        const result = await notificationService.contactUs(newFormData)
        if (result.status === 'success') {
            setMessageStatus('success')
            setMessageClass('alert alert-dismissible fade show alert-success')
            setMessageText(result.message)
            setFormData({
                ...formData,
                fromName: '',
                fromEmail: '',
                fromCompany: '',
                notificationMessage: '',
            })
            handleLoaded()
        }
        else {
            setMessageStatus('danger')
            setMessageClass('alert alert-dismissible fade show alert-danger')
            setMessageText(result.message)
            handleLoaded()
            if (result.status === 'validation_error') {
                result.error.forEach((error) => {
                    document.getElementById(error.field).classList.add('is-invalid')
                })
            }
        }
    }

    const btnAlertDismissOnClick = () => {
        setTimeout(() => {
            setMessageText('')
            setMessageStatus('')
            setMessageClass('alert alert-dismissible fade')
        }, 200)
    }

    const currentYear = new Date().getFullYear() % 100

    return (
        <main className="theme-light">
            <div className="banner banner-secondary rounded-0">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 col-12 pt-lg-5 px-lg-0 px-4">
                            <h1 className="fw-light text-white mx-auto mb-0 pt-3"><span className="fw-medium">Concurso Compartiendo</span><br/><span className="fw-bolder">Buenas Prácticas en Seguridad y Salud en el Trabajo</span></h1>
                        </div>
                    </div>
                </div>
                <div className="txt-year">
                    20<br /><span>
                        {currentYear}
                    </span>
                </div>
            </div>
            <div className="container py-5">
                <div className="row pt-5">
                    <div className="col-md-7 pb-5">
                        <h1 className="h2 fw-bold mb-5">Contacto</h1>
                        <form action="">
                            <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="">Nombre Completo</label>
                                <input
                                    type="text"
                                    id="fromName"
                                    name="fromName"
                                    className="form-control form-control-lg rounded-pill"
                                    placeholder="Ingrese su nombre completo"
                                    onChange={handleInputChange}
                                    value={formData.fromName}
                                    required
                                />
                            </div>
                            <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="">Nombre Empresa</label>
                                <input
                                    type="text"
                                    id="fromCompany"
                                    name="fromCompany"
                                    className="form-control form-control-lg rounded-pill"
                                    placeholder="Ingrese el nombre de su empresa"
                                    onChange={handleInputChange}
                                    value={formData.fromCompany}
                                    required
                                />
                            </div>
                            <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="">Email</label>
                                <input
                                    type="email"
                                    id="fromEmail"
                                    name="fromEmail"
                                    className="form-control form-control-lg rounded-pill"
                                    placeholder="Ingrese su email"
                                    onChange={handleInputChange}
                                    value={formData.fromEmail}
                                    required
                                />
                            </div>
                            <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="">Mensaje</label>
                                <textarea
                                    name="notificationMessage"
                                    id="notificationMessage"
                                    cols="30"
                                    rows="10"
                                    className="form-control form-control-lg"
                                    placeholder="Ingrese su mensaje"
                                    onChange={handleInputChange}
                                    required
                                    value={formData.notificationMessage}
                                >
                                </textarea>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div
                                    className="g-recaptcha"
                                    data-sitekey={process.env.REACT_APP_SITE_RECAPTCHA_KEY}
                                    data-size="invisible"
                                ></div>
                                <input
                                    type="button"
                                    className="btn btn-primary rounded-pill my-3 px-5 py-2"
                                    value="Enviar"
                                    onClick={btnSendOnClick}
                                />
                            </div>
                            {
                                messageStatus !== '' && (
                                    <div className="form-outline mb-3">
                                        <div className={messageClass}
                                            role="alert">
                                            {messageText}
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="alert"
                                                aria-label="Close"
                                                onClick={btnAlertDismissOnClick}
                                            ></button>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                    </div>
                    <div className="col-md-4 ms-md-auto me-lg-4 me-0">
                        <div className="row row-cols-2 g-4 text-center mt-2 nav-icons">
                            <div className="col">
                                <a onClick={
                                    btnBasesOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_BASES_CONCURSO
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Bases del Concurso</span>
                            </div>
                            <div className="col">
                                <a onClick={
                                    btnBuenasPracticasOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_BUENAS_PRACTICAS
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Buenas Prácticas</span>
                            </div>
                            <div className="col">
                                <a onClick={
                                    btnFaqOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_PREGUNTAS_FRECUENTES
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Preguntas Frecuentes</span>
                            </div>
                            <div className="col page-active">
                                <a onClick={
                                    btnContactoOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_CONTACTO
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1">Contacto</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
}
