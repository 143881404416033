import APIDriver from './api/APIDriver'
const api = new APIDriver('tickets')

const methods = {
    byCompany: (id) => {
        api.setBearerProtection();
        return api.get('company', id);
    },
    byUser: (id) => {
        api.setBearerProtection();
        return api.get('user', id);
    },
    byCompanyUser: (id) => {
        api.setBearerProtection();
        return api.get('company-user', id);
    },
    byApplication: (id) => {
        api.setBearerProtection();
        return api.get('application', id);
    },
    get: (id) => {
        api.setBearerProtection();
        return api.get('', id);
    },
    list: () => {
        api.setBearerProtection();
        return api.getAll('');
    },
    create: (data) => {
        api.setBearerProtection();
        return api.post('', data);
    },
    update: (id, data) => {
        api.setBearerProtection();
        return api.patch('', id, data);
    },
    remove: (id) => {
        api.setBearerProtection();
        return api.delete('', id);
    }
}


export default methods;