import React from 'react'
import PhotoGalleryItem from './PhotoGalleryItem'

export default function PhotoGalleryRender() {

    const baseUrl = 'https://mbp-cdn.nyc3.digitaloceanspaces.com';
    const itemsPerPage = 15;
    const maxPaginationButtons = 3;
    const firstGalleryItem = 0;
    const lastGalleryItem = 98;
    const photoNamePrefix = 'ceremonia_premiacion_cbpsst_2023_';
    const [currentPage, setCurrentPage] = React.useState(1);
    const maxPages = Math.ceil((lastGalleryItem - firstGalleryItem + 1) / itemsPerPage);

    var PrependZeros = function (str, len, seperator) {
        if (typeof str === 'number' || Number(str)) {
            str = str.toString();
            return (len - str.length > 0) ? new Array(len + 1 - str.length).join('0') + str : str;
        }
        else {
            for (var i = 0, spl = str.split(seperator || ' '); i < spl.length; spl[i] = (Number(spl[i]) && spl[i].length < len) ? PrependZeros(spl[i], len) : spl[i], str = (i == spl.length - 1) ? spl.join(seperator || ' ') : str, i++);
            return str;
        }
    };

    return (
        <div className="album py-5 bg-light">
            <div className="container">

                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">

                    {/* Gallery of PhotoGalleryItem using Pagination */}

                    {
                        Array.from({ length: itemsPerPage }).map((_, index) => {
                            const item = index + (currentPage - 1) * itemsPerPage;
                            //photo name has a structure like this: ceremonia_premiacion_cbpsst_2023_081.jpg

                            let fixedItemName = '';

                            fixedItemName = PrependZeros(item, 3);

                            return (
                                item >= firstGalleryItem && item <= lastGalleryItem &&
                                <PhotoGalleryItem
                                    key={item}
                                    photo={`${baseUrl}/2023/${photoNamePrefix}${fixedItemName}.jpg`}
                                />
                            )
                        })
                    }

                </div>

                {/* Pagination */}

                <div className="d-flex justify-content-center my-5">
                    <div className="btn-group" role="group" aria-label="Basic example">

                        {
                            currentPage > 1 &&
                            <>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => setCurrentPage(1)}
                                >
                                    &lt;&lt;
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    &lt;
                                </button>
                            </>
                        }
                        {
                            Array.from({ length: maxPages }).map((_, index) => {
                                const page = index + 1;
                                const isCurrentPage = page === currentPage;
                                const isNearCurrentPage = page >= currentPage - maxPaginationButtons && page <= currentPage + maxPaginationButtons;
                                if (isCurrentPage || isNearCurrentPage) {
                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            className={`btn ${isCurrentPage ? 'btn-primary' : 'btn-outline-primary'}`}
                                            onClick={() => setCurrentPage(page)}
                                        >
                                            {page}
                                        </button>
                                    )
                                }
                                return null;
                            })
                        }
                        {
                            currentPage < maxPages &&
                            <>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    &gt;
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => setCurrentPage(maxPages)}
                                >
                                    &gt;&gt;
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
