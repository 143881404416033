import APIDriver from './api/APIDriver'
const api = new APIDriver('applicationfiles')

const methods = {
    byApplication: (id) => {
        api.setBearerProtection();
        return api.get('application', id);
    },
    byFile: (id) => {
        api.setBearerProtection();
        return api.get('file', id);
    },
    get: (id) => {
        api.setBearerProtection();
        return api.get('', id);
    },
    list: () => {
        api.setBearerProtection();
        return api.getAll('');
    },
    create: (data) => {
        api.setBearerProtection();
        return api.post('', data);
    },
    update: (id, data) => {
        api.setBearerProtection();
        return api.patch('', id, data);
    },
    remove: (id) => {
        api.setBearerProtection();
        return api.delete('', id);
    }
}


export default methods;