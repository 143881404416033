import React from 'react'
import IMG_CERO_FATALES from '../../assets/img/cero_fatales.png'
import IMG_VISION_ZERO from '../../assets/img/vision_zero.png'
import IMG_MUTUAL_SEGURIDAD from '../../assets/img/mutual_seguridad.png'
import IMG_CAMARA_CHILENA from '../../assets/img/camara_chilena.png'


export default function Footer() {
    return (
        <footer className='mt-5'>
            <div className="container mt-5">
                <div className="row row-cols-md-4 row-cols-1 g-5 d-lg-flex align-items-center pb-4">
                    <div className="col">
                        <a href='https://cerofatales.cl' target='_blank'><img src={
                            IMG_CERO_FATALES
                        } className="img-fluid d-block mx-auto" /></a>
                    </div>
                    <div className="col">
                        <a href='https://visionzero.global/es' target='_blank'><img src={
                            IMG_VISION_ZERO
                        } className="img-fluid d-block mx-auto" /></a>
                    </div>
                    <div className="col">
                        <a href='https://www.mutual.cl' target='_blank'><img src={
                            IMG_MUTUAL_SEGURIDAD
                        } className="img-fluid d-block mx-auto" /></a>
                    </div>
                    <div className="col">
                        <a href='https://cchc.cl' target='_blank'><img src={
                            IMG_CAMARA_CHILENA
                        } className="img-fluid d-block mx-auto" /></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
