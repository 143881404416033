import React from 'react'
import IMG_ICON_BASES_CONCURSO from '../../../assets/img/icon02-bases-concurso.svg'
import IMG_ICON_BUENAS_PRACTICAS from '../../../assets/img/icon02-buenas-practicas.svg'
import IMG_ICON_PREGUNTAS_FRECUENTES from '../../../assets/img/icon02-preguntas-frecuentes.svg'
import IMG_ICON_CONTACTO from '../../../assets/img/icon02-contacto.svg'
import { useNavigate } from 'react-router-dom'

export default function Main() {

    const navigateTo = useNavigate()

    const btnBasesOnClick = () => {
        navigateTo('/competition-rules')
    }

    const btnBuenasPracticasOnClick = () => {
        navigateTo('/good-practices')
    }

    const btnFaqOnClick = () => {
        navigateTo('/faq')
    }

    const btnContactoOnClick = () => {
        navigateTo('/contact-us')
    }

    const btnIngresarOnClick = () => {
        alert('TODO: deriva a otro subproyecto')
    }

    const [videoModalShow, setVideoModalShow] = React.useState(false)

    const [videoURL, setVideoURL] = React.useState('')

    const openVideoModal = () => {
        setVideoURL('https://www.youtube.com/embed/JFm1Nmj6CaM')
        setVideoModalShow(true)
    }

    const closeVideoModal = () => {
        setVideoURL('')
        setVideoModalShow(false)
    }

    const currentYear = new Date().getFullYear() % 100

    return (
        <>
            <main className="theme-light">
                <div className="banner banner-primary rounded-0">
                    <div className="container py-4">
                        <div className="row">
                            <div className="col-lg-6 col-12 pt-lg-5 px-lg-0 px-4">
                                <h1 className="fw-light text-white mx-auto mb-0 pt-3"><span className="fw-medium">Concurso Compartiendo</span><br /><span className="fw-bolder">Buenas Prácticas en Seguridad y Salud en el Trabajo</span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="txt-year">
                        20<br /><span>
                            {currentYear}
                        </span>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="row pt-5">
                        <div className="col-md-7 pb-5">
                            <h1 className="h2 fw-bold mb-5">Preguntas Frecuentes</h1>

                            <h3 className="h5 fw-bold">¿Cómo postular?</h3>
                            <p>Para postular, cada empresa deberá llenar el formulario que se encontrará disponible en la página web http://www.buenaspracticasensst.cl adjuntando todos los antecedentes y documentos que sirvan de respaldo y fundamenten el mérito de la buena práctica presentada.</p>
                            <hr className="my-4" />
                            <h3 className="h5 fw-bold">¿Cuáles son los roles de usuario disponibles al crear una cuenta en el formulario de registro?</h3>
                            <p>Durante el proceso de generación de una cuenta nueva a través del formulario de registro, el sistema procederá a crear un registro de Empresa, siendo el primer usuario asignado a esta cuenta el rol de "Administrador".</p>
                            <p>El rol de "Administrador" otorga la capacidad de gestionar los usuarios dentro de la empresa, permitiendo la creación, edición y eliminación de los mismos. Además, se le brinda la habilidad de crear, editar y enviar postulaciones relacionadas a las buenas prácticas.</p>
                            <p>Por otro lado, el rol de "Agente" habilita la posibilidad de crear, editar y enviar postulaciones relacionadas a las buenas prácticas, sin tener la capacidad de administrar usuarios dentro de la plataforma.</p>
                            <hr className="my-4" />
                            <h3 className="h5 fw-bold">¿Puedo postular más de una práctica?</h3>
                            <p>Sí, puedes postular más de una práctica, sin embargo, la empresa postulante no podrá presentar buenas prácticas duplicadas, entendiéndose como tales, aquellas que habiéndose implementado en diferentes centros de trabajo/área interna/división de la empresa, correspondan al mismo problema a resolver y su respectiva solución. Para efectos del concurso, se considerará a la empresa postulante como responsable de centralizar la información de sus buenas prácticas. En el caso de presentarse duplicidad, la CChC y Mutual se reservan el derecho a dejar fuera de bases y eliminar una de las buenas prácticas duplicadas, quedando a criterio de dichas instituciones la selección de la buena práctica que continuará en el concurso.</p>
                            <hr className="my-4" />
                            <h3 className="h5 fw-bold">¿Puedo postular con distintas obras, faenas y/o centros de trabajo?</h3>
                            <p>Sí, puedes postular buenas prácticas implementadas en distintos centros de trabajo, faenas u obras. Se recomienda realizar concursos internos que permitan la postulación de prácticas obtenidas de dicha instancia.</p>
                            <hr className="my-4" />
                            <h3 className="h5 fw-bold">Si mi Buena Práctica no ganó en versiones anteriores, ¿la puedo volver a postular?</h3>
                            <p>Sí, sin embargo, se recomienda la revisión y/o mejora de la postulación y los antecedentes presentados, todas vez que las prácticas son revisadas una a una por un equipo técnico especialista, y serán descartadas en el caso de que se presenten postulaciones exactamente iguales a las de versiones anteriores.</p>
                        </div>
                        <div className="col-md-4 ms-md-auto me-lg-4 me-0">
                            <div className="row text-center">
                                <div className="col">
                                    <button className="rounded-pill px-5 py-3 react-link btn tutorial-button" onClick={openVideoModal}>
                                        <span className='me-4'>Mira cómo participar</span>
                                        <svg width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M34.7917 11.0625V2.45833C34.7917 1.80634 34.5327 1.18106 34.0716 0.72003C33.6106 0.259003 32.9853 0 32.3333 0H2.83333C2.18134 0 1.55606 0.259003 1.09503 0.72003C0.634002 1.18106 0.375 1.80634 0.375 2.45833V27.0417C0.375 27.6937 0.634002 28.3189 1.09503 28.78C1.55606 29.241 2.18134 29.5 2.83333 29.5H32.3333C32.9853 29.5 33.6106 29.241 34.0716 28.78C34.5327 28.3189 34.7917 27.6937 34.7917 27.0417V18.4375L44.625 28.2708V1.22917L34.7917 11.0625Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="row row-cols-2 g-4 text-center mt-2 nav-icons">
                                <div className="col">
                                    <a onClick={btnBasesOnClick} className="p-3 d-block react-link">
                                        <img src={IMG_ICON_BASES_CONCURSO} className="img-fluid" />

                                    </a>
                                    <span className="small fw-bold lh-1 text-wrap" style={{ width: '6rem' }}>Bases del Concurso</span>
                                </div>
                                <div className="col">
                                    <a onClick={btnBuenasPracticasOnClick} className="p-3 d-block react-link">
                                        <img src={IMG_ICON_BUENAS_PRACTICAS} className="img-fluid" />

                                    </a>
                                    <span className="small fw-bold lh-1 text-wrap" style={{ width: '6rem' }}>Buenas Prácticas</span>
                                </div>
                                <div className="col  page-active">
                                    <a onClick={btnFaqOnClick} className="p-3 d-block react-link">
                                        <img src={IMG_ICON_PREGUNTAS_FRECUENTES} className="img-fluid" />

                                    </a>
                                    <span className="small fw-bold lh-1 text-wrap" style={{ width: '6rem' }}>Preguntas Frecuentes</span>
                                </div>
                                <div className="col">
                                    <a onClick={btnContactoOnClick} className="p-3 d-block react-link">
                                        <img src={IMG_ICON_CONTACTO} className="img-fluid" />

                                    </a>
                                    <span className="small fw-bold lh-1">Contacto</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div
                className={`modal fade ${videoModalShow ? 'show' : ''}`}
                style={{ display: videoModalShow ? 'block' : 'none' }}
            >
                <div style={ 
                    {
                        zIndex: 1040,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        opacity: 0.7,
                    }
                } className="modal-backdrop bg-dark"></div>
                <div style={
                    {
                        zIndex: 1050,
                    }
                } className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeVideoModal}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {/*Show a youtube video embeded here*/}
                            <iframe
                                width="100%"
                                height="640"
                                src={videoURL}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
