import React from 'react'
//import '../assets/css/style.css'
import IMG_404 from '../assets/img/error-404.svg'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {

  const navigateTo = useNavigate()

  const btnGoRoot = () => {
    navigateTo('/')
  }

  return (
    <main id="welcome">
      <div className="container-fluid vh-100">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center p-5 welcome-col">
            <div className="welcome-title mx-auto">
              <img src={IMG_404} alt="Concurso Buenas Practicas Error 404" className="img-fluid mb-5" />
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center sign-in px-lg-5 py-5">
            <div className="m-auto">
              <h4 className="h3 fw-bolder mb-2" style={
                {
                  color: '#196DD9',
                }
              }>Oops!!</h4>
              <h1 className="fw-bolder" style={
                {
                  color: '#212529',
                }
              }>Esta Página no Existe.</h1>
              <p>La página que estabas buscando no se encuentra disponible.</p>
              <a onClick={btnGoRoot}
                className="btn btn-primary nf my-3 py-2 react-link">Volver al Inicio</a>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
