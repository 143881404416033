import React, { useState, useEffect, Fragment } from 'react'
import IMG_ICON_BASES_CONCURSO from '../../../assets/img/icon-bases-concurso.svg'
import IMG_ICON_BUENAS_PRACTICAS from '../../../assets/img/icon-buenas-practicas.svg'
import IMG_ICON_PREGUNTAS_FRECUENTES from '../../../assets/img/icon-preguntas-frecuentes.svg'
import IMG_ICON_CONTACTO from '../../../assets/img/icon-contacto.svg'
import { useNavigate } from 'react-router-dom'
import contestService from '../../../services/contests'
import fileService from '../../../services/files'
import contestFileService from '../../../services/contestFiles'

export default function Main() {

    const navigateTo = useNavigate()
    const [activeContest, setActiveContest] = useState(null)
    const [listData, setListData] = useState([])

    const btnBasesOnClick = () => {
        navigateTo('/competition-rules')
    }

    const btnBuenasPracticasOnClick = () => {
        navigateTo('/good-practices')
    }

    const btnFaqOnClick = () => {
        navigateTo('/faq')
    }

    const btnContactoOnClick = () => {
        navigateTo('/contact-us')
    }

    const handleContestLoading = async () => {
        try {
            const result = await contestService.showActive()
            setActiveContest(result)
            const fileList = await contestFileService.byContest(result.id)
            setListData(fileList)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        handleContestLoading()
    }, [])

    const handleFileDownload = async (item) => {
        const fileResult = await fileService.get(item.file_id)
        if (!fileResult || !fileResult.fileBase64) return alert(
            fileResult.fileErrorMessage
        )
        const linkSource = `data:${fileResult.fileMimeType};base64,${fileResult.fileBase64}`
        const downloadLink = document.createElement("a")
        const fileName = item.contestFileName
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
    }

    const currentYear = new Date().getFullYear() % 100

    return (
        <main className="theme-blue">
            <div className="banner banner-home rounded-0">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 col-12 pt-lg-5 px-lg-0 px-4">
                            <h1 className="fw-light text-white mx-auto mb-0 pt-3"><span className="fw-medium">Concurso Compartiendo</span><br /><span className="fw-bolder">Buenas Prácticas en Seguridad y Salud en el Trabajo</span></h1>
                        </div>
                    </div>
                </div>
                <div className="txt-year">
                    20<br /><span>
                        {currentYear}
                    </span>
                </div>
            </div>
            <div className="container py-5">
                <div className="row pt-5">
                    <div className="col-md-7 pb-5">
                        <h1 className="h2 fw-bold">Concurso Compartiendo Buenas Prácticas de Seguridad y Salud en el Trabajo.</h1>
                        <h2 className="h3 fw-light">Décimo séptima versión.<br />Año 2024.</h2>
                        <hr className="my-4" />
                        <h6 className="mb-4">Considerando:</h6>

                        <p>1. Que la Cámara Chilena de la Construcción A.G., en adelante, indistintamente, “Cámara” o “CChC” es una asociación gremial, que tiene por misión “Mejorar la calidad de vida de las personas, comprometidos con el desarrollo sostenible del sector de la construcción”. Para ello, la Cámara promoverá en todo su quehacer los valores de, ética, colaboración, sostenibilidad y visión de futuro”.</p>
                        <p>2. Que en el camino al Desarrollo Empresarial Sostenible, la Cámara promueve entre sus Empresas Socias el sello Compromiso PRO. Este Compromiso impulsa una manera de hacer las cosas que genere valor en la sociedad a partir de siete dimensiones: Gobernanza, Trabajadores, Comunidad, Medio Ambiente Seguridad y Salud Laboral, Proveedores y Clientes, Innovación y Productividad. El Concurso Compartiendo Buenas Prácticas de Seguridad y Salud en el Trabajo resulta alineado con el propósito del Compromiso PRO, particularmente con la dimensión Seguridad y Salud de los trabajadores.</p>
                        <p>3. Que, por su parte, la Mutual de Seguridad de la Cámara Chilena de la Construcción, en adelante indistintamente “Mutual”, es un Organismo Administrador de la Ley N° 16.744, cuya misión es agregar valor a las empresas y sus trabajadores, entregando protección ante riesgos laborales, promoviendo ambientes de trabajo seguro, calidad de vida a las personas y fomentando una cultura de seguridad y vida sana en la comunidad.</p>
                        <p>4. Que, aun cuando la accidentabilidad ha ido disminuyendo año tras año, la tasa de mortalidad1 por accidentes del trabajo de Mutualidades e ISL, durante el año 2023 a nivel país, fue de 2,6 por cada 100.000 trabajadores protegidos y para la actividad económica de la construcción considerando el mismo año, fue de 5,0 por cada 100.000 trabajadores, según información entregada por SUSESO</p>
                        <p>5.  Que estas cifras, reflejan el hecho de que los trabajadores de la construcción deben enfrentar riesgos que son potencialmente graves y/o fatales en la realización de sus funciones. En este contexto, surge la necesidad de implementar nuevas y mejores formas de gestionar el riesgo, que nos permitan contar día a día con un lugar de trabajo más seguro. </p>
                        <p>6. Que, conscientes de esta realidad, la Mesa Directiva Nacional de la Cámara Chilena de la Construcción A.G., en conjunto con Mutual de Seguridad CChC, desde el año 2008 organizan el Concurso “COMPARTIENDO BUENAS PRÁCTICAS DE SEGURIDAD Y SALUD EN EL TRABAJO”, siendo, la del año 2024, su decimoséptima convocatoria.</p>
                        <p>7. Que los premios que se entreguen como resultado del Concurso “Compartiendo Buenas Prácticas en Seguridad y Salud en el Trabajo 2024”, se otorgarán de acuerdo a las siguientes Bases.</p>
                        <div className="d-flex justify-content-end mt-5">
                            {
                                activeContest && listData.length > 0 && listData.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <a
                                                target="_blank"
                                                className="btn-bases rounded-pill px-4 py-2 ms-3 react-link"
                                                onClick={
                                                    () => {
                                                        handleFileDownload(item)
                                                    }
                                                }
                                            >
                                                {item.contestFileName}
                                            </a>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-md-4 ms-md-auto me-lg-4 me-0">
                        <div className="row row-cols-2 g-4 text-center mt-2 nav-icons">
                            <div className="col page-active">
                                <a onClick={
                                    btnBasesOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_BASES_CONCURSO
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Bases del Concurso</span>
                            </div>
                            <div className="col">
                                <a onClick={
                                    btnBuenasPracticasOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_BUENAS_PRACTICAS
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Buenas Prácticas</span>
                            </div>
                            <div className="col">
                                <a onClick={
                                    btnFaqOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_PREGUNTAS_FRECUENTES
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Preguntas Frecuentes</span>
                            </div>
                            <div className="col">
                                <a onClick={
                                    btnContactoOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_CONTACTO
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1">Contacto</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
}
