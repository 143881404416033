import APIDriver from './api/APIDriver'
const api = new APIDriver('applications')

const methods = {
    byCompany: (id) => {
        api.setBearerProtection();
        return api.get('company', id);
    },
    byContest: (id) => {
        api.setBearerProtection();
        return api.get('contest', id);
    },
    byCompanyUser: (id) => {
        api.setBearerProtection();
        return api.get('company-user', id);
    },
    byUser: (id) => {
        api.setBearerProtection();
        return api.get('user', id);
    },
    byFilter: (data) => { 
        api.setBearerProtection();
        return api.post('filter', data);
    },
    patch: (id, data) => {
        api.setBearerProtection();
        return api.patch('patch', id, data);
    },
    get: (id) => {
        api.setBearerProtection();
        return api.get('', id);
    },
    list: () => {
        api.setBearerProtection();
        return api.getAll('');
    },
    search: (filters) => {
        api.setBearerProtection();
        return api.getAll('search', filters);
    },
    create: (data) => {
        api.setBearerProtection();
        return api.post('', data);
    },
    update: (id, data) => {
        api.setBearerProtection();
        return api.patch('', id, data);
    },
    remove: (id) => {
        api.setBearerProtection();
        return api.delete('', id);
    }
}


export default methods;