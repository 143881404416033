import { Buffer } from "buffer";

const methods = {
    removeCredentials: () => {
        localStorage.removeItem('bearer-token');
    },
    setCredentials: (token) => {
        localStorage.setItem('bearer-token', token);
    },
    getCredentials: () => {
        return localStorage.getItem('bearer-token');
    },
    getParsedCredentials: () => {
        try {
            let token = localStorage.getItem('bearer-token');
            if (token) {
                return JSON.parse(Buffer.from(token, 'base64').toString('ascii'));
            } else {
                throw new Error('No token found');
            }
        } catch (error) {
            return null;
        }
    }
}

export default methods;