import React, { Fragment } from 'react'
import Select from 'react-select';

import contestService from '../../../services/contests'
import categoryService from '../../../services/categories'
import tagService from '../../../services/tags'
import applicationService from '../../../services/applications'

export default function Search() {

    const focusRef = React.useRef(null)
    const categoryRef = React.useRef(null)
    const tagsRef = React.useRef(null)
    const pageRef1 = React.useRef(null)
    const pageRef2 = React.useRef(null)

    const [contests, setContests] = React.useState(null)
    const [categories, setCategories] = React.useState(null)
    const [tags, setTags] = React.useState(null)

    const contestOptions = [
        {
            value: '',
            focus: 'Todos los focos disponibles',
            label: 'Todas las versiones'
        }
    ]

    const contestCategories = [
        { value: '', label: 'Todas las categorías' },
    ]

    const contestTags = [

    ]

    const [selectedContest, setSelectedContest] = React.useState(contestOptions[0])
    const [selectedCategory, setSelectedCategory] = React.useState(contestCategories[0])
    const [selectedTags, setSelectedTags] = React.useState(null)
    const [searchResult, setSearchResult] = React.useState(null)
    const [searchResultSetup, setSearchResultSetup] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState(1)
    const [loading, setLoading] = React.useState(false)
    const [firstTime, setFirstTime] = React.useState(true)

    const handleContestChange = async (selectedOption) => {
        focusRef.current.value = selectedOption.focus
        if (categoryRef.current)
            categoryRef.current.value = null
        else
            categoryRef.current = null
        tagsRef.current.value = null
        setSelectedContest(selectedOption)
        setSelectedCategory(contestCategories[0])
        setSelectedTags(null)
        await handleCategoryLoad(selectedOption.value)
        await handleTagLoad(selectedOption.value, '')

    }

    const handleCategoryChange = async (selectedOption) => {
        setSelectedCategory(selectedOption)

        await handleTagLoad(selectedContest.value, selectedOption.value)
        setSelectedTags(null)
        tagsRef.current.value = null
    }

    const handleTagChange = (selectedOption) => {
        setSelectedTags(selectedOption)
    }

    const handleContestLoad = async () => {
        const contestResult = await contestService.list()

        if (contestResult) {

            let newContests = [
                contestOptions[0],
                ...contestResult
                    .filter(
                        contest => contest.contestStatus === 'activo'
                    )
                    .map(contest => {
                        let focus = contest.categoryGroup?.categoryGroupName || 'Todos los focos disponibles'
                        let year = contest.created_at.date.split('-')[0]
                        return {
                            value: contest.id,
                            focus: focus,
                            label: `${contest.contestName} (${year})`
                        }
                    })
            ]

            setContests(
                newContests
            )
        }

        await handleCategoryLoad(selectedContest.value)
        await handleTagLoad(selectedContest.value, '')

    }

    const handleCategoryLoad = async (contest_id) => {

        if (contest_id === '') {

            const allCategoryResult = await categoryService.list()
            if (allCategoryResult) {
                let newCategories = [
                    contestCategories[0],
                    ...allCategoryResult
                        .map(category => {
                            return {
                                value: category.id,
                                label: category.categoryName
                            }
                        })
                ]
                setCategories(
                    newCategories
                )
            }
        } else {
            const categoryResult = await categoryService.byContest(contest_id)
            if (categoryResult) {
                let newCategories = [
                    contestCategories[0],
                    ...categoryResult
                        .map(category => {
                            return {
                                value: category.id,
                                label: category.categoryName
                            }
                        })
                ]
                setCategories(
                    newCategories
                )
            }
        }

    }
    const handleTagLoad = async (contest_id, category_id) => {
        let newTags = [];
        if (contest_id === '') {
            const allTagResult = await tagService.list()

            if (allTagResult) {

                //get different contests

                let contests = allTagResult
                    .map(tag => tag.category.categoryGroup.contest)
                    .filter((contest, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === contest.id
                        ))
                    )

                contests = contests.map(contest => {
                    let bgColor = "#F1F0F1"
                    let textColor = "#333333"
                    return {
                        ...contest,
                        bgColor: bgColor,
                        textColor: textColor
                    }
                })

                newTags = [
                    ...allTagResult
                        .map(tag => {
                            return {
                                value: tag.id,
                                label: tag.tagName,
                                category: tag.category,
                                categoryGroup: tag.category.categoryGroup,
                                contest: tag.category.categoryGroup.contest,
                                bgColor: contests.find(contest => contest.id === tag.category.categoryGroup.contest.id).bgColor,
                                textColor: contests.find(contest => contest.id === tag.category.categoryGroup.contest.id).textColor
                            }
                        })
                ]
                setTags(
                    newTags
                )
            }
        } else if (category_id === '') {
            const tagResult = await tagService.byContest(contest_id)
            if (tagResult) {
                newTags = [
                    ...tagResult
                        .map(tag => {
                            return {
                                value: tag.id,
                                label: tag.tagName,
                                category: tag.category,
                                categoryGroup: tag.category.categoryGroup,
                                contest: tag.category.categoryGroup.contest
                            }
                        })
                ]
                setTags(
                    newTags
                )
            }
        } else {
            const tagResult = await tagService.byCategory(category_id)
            if (tagResult) {
                newTags = [
                    ...tagResult
                        .map(tag => {
                            return {
                                value: tag.id,
                                label: tag.tagName,
                                category: tag.category,
                                categoryGroup: tag.category.categoryGroup,
                                contest: tag.category.categoryGroup.contest
                            }
                        })
                ]
                setTags(
                    newTags
                )
            }
        }
    }

    const handleSearch = async () => {
        setLoading(true)
        let contest = selectedContest.value
        let category = selectedCategory.value
        let tags;

        if (selectedTags) {
            tags = selectedTags.map(tag => tag.value)
            if (tags.length === 0) tags = ''
        } else {
            tags = ''
        }

        console.log(
            {
                contest: contest,
                category: category,
                tags: tags,
            }
        )

        const filteredResult = await applicationService.byFilter({
            contest: contest,
            category: category,
            tags: tags,
            currentPage: currentPage
        })

        if (filteredResult) {
            setSearchResult(filteredResult.pageData)
            setSearchResultSetup({
                currentPage: filteredResult.currentPage,
                pageData: filteredResult.pageData,
                totalData: filteredResult.totalData,
                totalPages: filteredResult.totalPages,
                totalSize: filteredResult.totalSize
            })
        } else {
            setSearchResult(null)
            setSearchResultSetup(null)
        }
        setLoading(false)

    }

    const handleNavigation = async (page) => {
        setCurrentPage(page)
        pageRef1.current.value = ''
        pageRef2.current.value = ''
    }

    React.useEffect(() => {
        if (!firstTime) {
            handleSearch()
        }
    }, [currentPage])

    React.useEffect(() => {
        if (searchResult) {
            console.log(searchResult)
        }
    }, [searchResult])

    React.useEffect(() => {
        handleContestLoad()
        setFirstTime(false)
    }, [])

    React.useEffect(() => {
        if (tags?.length > 0) {
            let tagSelect = document.querySelector('.tag-select')
            if (tagSelect) {
                let tagSelectDiv = tagSelect.querySelector('div')
                if (tagSelectDiv) {
                    tagSelectDiv.style.padding = '5px 0'
                }
            }
        }
    }, [tags])

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12 mb-3'>
                    <label className='form-label'>Concurso</label>
                    <Select
                        options={contests}
                        value={selectedContest}
                        onChange={handleContestChange}
                        placeholder='Seleccione un concurso'
                        name='contest'
                        styles={
                            {
                                singleValue: (styles, { data }) => {
                                    return {
                                        ...styles,
                                        padding: '10px 10px'
                                    }
                                }
                            }
                        }
                    />
                </div>
                <div className='col-12 mb-3'>
                    <label className='form-label'>Foco</label>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Todos los focos disponibles'
                        name='focus'
                        ref={focusRef}
                        readOnly={true}
                        style={
                            {
                                padding: '10px 20px'
                            }
                        }
                    />
                </div>
                {
                    selectedContest?.value !== '' &&
                    <div className='col-12 mb-3'>
                        <label className='form-label'>Categoría</label>
                        <Select
                            options={categories}
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            placeholder='Seleccione una categoría'
                            name='category'
                            ref={categoryRef}
                            styles={
                                {
                                    singleValue: (styles, { data }) => {
                                        return {
                                            ...styles,
                                            padding: '10px 10px'
                                        }
                                    }
                                }
                            }
                        />
                    </div>
                }

                {
                    tags?.length > 0 && <div className='col-12 mb-3'>
                        <label className='form-label'>Etiquetas</label>
                        <Select
                            options={
                                selectedContest?.value === '' ?
                                    tags.map(tag => {
                                        let year = tag.contest.created_at.date.split('-')[0]
                                        return {
                                            value: tag.value,
                                            label: `${tag.label} (${year})`,
                                            bgColor: tag.bgColor,
                                            textColor: tag.textColor
                                        }
                                    })
                                    :
                                    selectedCategory?.value === '' ?
                                        tags.map(tag => {
                                            let year = tag.contest.created_at.date.split('-')[0]
                                            return {
                                                value: tag.value,
                                                label: `${tag.label} (${year})`
                                            }
                                        }
                                        )
                                        :
                                        tags.map(tag => {
                                            return {
                                                value: tag.value,
                                                label: tag.label
                                            }
                                        }
                                        )
                            }
                            value={selectedTags}
                            onChange={handleTagChange}
                            placeholder='Todas las etiquetas'
                            name='tag'
                            ref={tagsRef}
                            isMulti={true}
                            styles={
                                {
                                    multiValue: (styles, { data }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: data.bgColor || '#F1F0F1',
                                            color: data.textColor || '#333333',
                                            padding: '5px 10px'
                                        }
                                    },
                                }
                            }
                            className='tag-select'
                        />
                    </div>
                }

                <div className='col-12 my-2 text-start'>
                    <button
                        className='btn btn-primary btn-block btn-lg'
                        onClick={handleSearch}
                    >
                        <i className='bi bi-search'></i> Buscar
                    </button>
                </div>
                {
                    searchResult === null && <div className='col-12 mt-5'>
                        <h5 className='text-muted'>Realice una búsqueda para ver los resultados</h5>
                    </div>
                }
                {
                    searchResult?.length === 0 && <div className='col-12 mt-5'>
                        <h5 className='text-muted'>No se encontraron resultados</h5>
                        <p className='text-muted'>Intente con otros filtros</p>
                    </div>
                }
                {
                    searchResult?.length > 0 && <div className='col-12 mt-5'>
                        <h5 className='text-muted'>Resultados de la búsqueda</h5>
                        <p className='text-muted'>
                            Se muestran {searchResult.length} de {searchResultSetup.totalSize} resultados (Página {searchResultSetup.currentPage} de {searchResultSetup.totalPages})
                        </p>
                        <div className='row card'>
                            <div className='col-12'>
                                <div className='row mt-4'>
                                    <div className='col-12 px-md-5'>
                                        <div className='form-group'>
                                            <select
                                                className='form-select'
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        handleNavigation(parseInt(e.target.value))
                                                    }
                                                }
                                                }
                                                defaultValue={''}
                                                ref={pageRef1}
                                            >
                                                <option value=''>Ir a página</option>
                                                {
                                                    [...Array(searchResultSetup.totalPages).keys()].map((page, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={page + 1}
                                                            >
                                                                {page + 1}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div aria-label="Page navigation" className='mt-3'>
                                    <ul className="pagination justify-content-center">
                                        <li className={`page-item ${searchResultSetup.currentPage === 1 ? 'disabled' : ''}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => handleNavigation(searchResultSetup.currentPage - 1)}
                                            >
                                                Anterior
                                            </button>
                                        </li>
                                        {
                                            //center on current page, show 2 pages before and 2 pages after
                                            [...Array(searchResultSetup.totalPages).keys()].map((page, index) => {
                                                if (searchResultSetup.currentPage === page + 1) {
                                                    return (
                                                        <li className="page-item active" aria-current="page" key={index}>
                                                            <button className="page-link">{page + 1}</button>
                                                        </li>
                                                    )
                                                } else if (page + 1 >= searchResultSetup.currentPage - 2 && page + 1 <= searchResultSetup.currentPage + 2) {
                                                    return (
                                                        <li className="page-item" key={index}>
                                                            <button
                                                                className="page-link"
                                                                onClick={() => handleNavigation(page + 1)}
                                                            >
                                                                {page + 1}
                                                            </button>
                                                        </li>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                        <li className={`page-item ${searchResultSetup.currentPage === searchResultSetup.totalPages ? 'disabled' : ''}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => handleNavigation(searchResultSetup.currentPage + 1)}
                                            >
                                                Siguiente
                                            </button>
                                        </li>
                                    </ul>

                                </div>
                                {
                                    loading && <div className='col-12 my-5 text-center'>
                                        <h5 className='text-muted'>Cargando resultados...</h5>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='table-responsive'>
                                <table className='table table-hover'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>#</th>
                                            <th scope='col'>Nombre</th>
                                            <th scope='col'>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            searchResult.map((application, index) => {
                                                return (
                                                    <tr key={application.id}>
                                                        <th scope='row'>
                                                            {
                                                                index + 1 + (searchResultSetup.currentPage - 1) * 6
                                                            }
                                                        </th>
                                                        <td>{application.applicationTitle}</td>
                                                        <td>
                                                            <a
                                                                href={`/good-practices/print/${application.id}`}
                                                                className='btn btn-primary'
                                                                target='_blank'
                                                            >
                                                                <i className='bi bi-eye'></i> Ver
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12'>
                                {
                                    loading && <div className='col-12 my-5 text-center'>
                                        <h5 className='text-muted'>Cargando resultados...</h5>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>

                                    </div>
                                }
                                <div aria-label="Page navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className={`page-item ${searchResultSetup.currentPage === 1 ? 'disabled' : ''}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => handleNavigation(searchResultSetup.currentPage - 1)}
                                            >
                                                Anterior
                                            </button>
                                        </li>
                                        {
                                            //center on current page, show 2 pages before and 2 pages after
                                            [...Array(searchResultSetup.totalPages).keys()].map((page, index) => {
                                                if (searchResultSetup.currentPage === page + 1) {
                                                    return (
                                                        <li className="page-item active" aria-current="page" key={index}>
                                                            <button className="page-link">{page + 1}</button>
                                                        </li>
                                                    )
                                                } else if (page + 1 >= searchResultSetup.currentPage - 2 && page + 1 <= searchResultSetup.currentPage + 2) {
                                                    return (
                                                        <li className="page-item" key={index}>
                                                            <button
                                                                className="page-link"
                                                                onClick={() => handleNavigation(page + 1)}
                                                            >
                                                                {page + 1}
                                                            </button>
                                                        </li>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                        <li className={`page-item ${searchResultSetup.currentPage === searchResultSetup.totalPages ? 'disabled' : ''}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => handleNavigation(searchResultSetup.currentPage + 1)}
                                            >
                                                Siguiente
                                            </button>
                                        </li>
                                    </ul>
                                    <div className='row mb-4'>
                                        <div className='col-12 px-md-5'>
                                            <div className='form-group'>
                                                <select
                                                    className='form-select'
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            handleNavigation(parseInt(e.target.value))
                                                        }
                                                    }
                                                    }
                                                    defaultValue={''}
                                                    ref={pageRef2}
                                                >
                                                    <option value=''>Ir a página</option>
                                                    {
                                                        [...Array(searchResultSetup.totalPages).keys()].map((page, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={page + 1}
                                                                >
                                                                    {page + 1}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    )
}
