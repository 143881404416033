import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as bootstrap from 'bootstrap'

import IMG_ConcursoBuenasPracticas from '../../assets/img/concurso_buenas_practicas.png'

export default function Navbar() {

    const navigateTo = useNavigate()
    const collapseRef = useRef()
    const collapseBtnRef = useRef()
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false)

    const btnIndexOnClick = () => {
        navigateTo('/home')
    }

    const btnBasesOnClick = () => {
        navigateTo('/competition-rules')
    }

    const btnBuenasPracticasOnClick = () => {
        navigateTo('/good-practices')
    }

    const btnFaqOnClick = () => {
        navigateTo('/faq')
    }

    const btnContactoOnClick = () => {
        navigateTo('/contact-us')
    }

    const btnIngresarOnClick = () => {
        window.open('https://empresa.buenaspracticasensst.cl/sign-on', '_blank')
    }

    const handleNavbarCollapse = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed)

    }

    useEffect(() => {
        if (isNavbarCollapsed) {
            collapseBtnRef.current.attributes['aria-expanded'].value = 'true'
            setTimeout(() => {
                //add collapse .show class
                collapseRef.current.classList.add('show')
            }, 200)
        } else {
            collapseBtnRef.current.attributes['aria-expanded'].value = 'false'
            setTimeout(() => {
                collapseRef.current.classList.remove('show')
            }, 400)
            
        }
    }, [isNavbarCollapsed])

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top py-0 px-lg-0 px-4">
            <div className="container-fluid px-lg-5">
                <a className="navbar-brand react-link" onClick={btnIndexOnClick}>
                    <img
                        src={IMG_ConcursoBuenasPracticas}
                        className="img-fluid"
                        style={
                            {
                                maxWidth: '320px'
                            }
                        }
                    />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarItem" aria-controls="collapse" aria-expanded="false" aria-label="Toggle navigation"
                    onClick={handleNavbarCollapse}
                    ref={collapseBtnRef}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse" id="navbarItem" ref={collapseRef}>
                    <ul className="navbar-nav ms-auto mb-4 mb-lg-0 text-end text-lg-start">
                        <li className="nav-item">
                            <a className={`nav-link ${window.location.pathname === '/competition-rules' ? 'active' : ''}`}
                                onClick={btnBasesOnClick}
                            >Bases</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${window.location.pathname === '/good-practices' ? 'active' : ''}`}
                                onClick={btnBuenasPracticasOnClick}
                            >Buenas Prácticas</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${window.location.pathname === '/faq' ? 'active' : ''}`}
                                onClick={btnFaqOnClick}
                            >Preguntas Frecuentes</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${window.location.pathname === '/contact-us' ? 'active' : ''}`}
                                onClick={btnContactoOnClick}
                            >Contacto</a>
                        </li>
                        <li className="nav-item ms-lg-4 ms-3 mt-lg-0 mt-4">
                            <a className="btn btn-primary d-block d-sm-inline-block rounded-pill px-4 py-0"
                                onClick={btnIngresarOnClick}
                            >Ingresar</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
