import React from 'react'
import FSLightBox from 'fslightbox-react'
export default function PhotoGalleryItem(props) {

    const { photo } = props
    const [showPhoto, setShowPhoto] = React.useState(false)

    return (

        <div className="col">
            <div className="card shadow-sm">
                <FSLightBox
                    toggler={showPhoto}
                    sources={[
                        <img src={photo} style={
                            { width: '100%' }
                        } />
                    ]}
                    type="image"
                />
                <a onClick={() => setShowPhoto(!showPhoto)}>
                    <img src={photo} className="img-fluid card-img-top" />
                </a>
            </div>
        </div>
    )
}
